const SIZAMINA_ROUTE_CONSTANTS = {
  ROOT: '/',
  ALL: '*',
  SIGNIN: 'signIn',
  COOKIEPOLICY: 'cookie-policy',
  TERMSANDCONDITIONS: 'terms-and-conditions',
  FREQUENTLYASKEDQUESTIONS: 'frequently-asked-questions',
  OUTLETS: 'outlets',
  CASHUPITEM: 'cashUpItemReport',
  STOCKINHAND: 'stockReport',
  STOCKTAKE: 'stockTake',
  STOCKADD: 'addStock',
};
export default SIZAMINA_ROUTE_CONSTANTS;
